import React from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import Cookies from "../helpers/Cookie";
import { timeStampLog } from "../utils/timeStampLog";

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axiosInstance.get("/auth/logout");
      Cookies.deleteAuthCookie();
      navigate("/login");
    } catch (error) {
      timeStampLog(`Logout error: ${error.message}`);
    }
  };

  return (
    <div className="absolute top-4 right-4 z-10">
      <div className="relative group">
        <button
          onClick={handleLogout}
          className="p-2 rounded-full hover:bg-gray-200 transition-colors bg-transparent"
          aria-label="Logout"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
        </button>
        <span className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 right-0 top-full mt-2">
          Logout
        </span>
      </div>
    </div>
  );
}

export default LogoutButton;
