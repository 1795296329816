import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axiosInstance from "../axiosConfig"; // Import your custom axios instance

const ThankYouPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [verified, setVerified] = useState(false);

  const company = searchParams.get("company") || "from circle.cloud";
  const date = searchParams.get("date") || "[Date on invite email]";
  const time = searchParams.get("time") || "[Time on invite email]";
  const leadId = searchParams.get("leadId");
  const zohoOrgId = searchParams.get("zohoOrgId");
  const token = searchParams.get("token");

  useEffect(() => {
    const verifyAcceptance = async () => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      try {
        const captchaToken = await executeRecaptcha("thank_you_page");

        console.log("Sending verification request with:", {
          captcha: captchaToken,
          leadId,
          zohoOrgId,
          token,
        });

        const response = await axiosInstance.post("/api/verify-acceptance", {
          captcha: captchaToken,
          leadId,
          zohoOrgId,
          token,
        });

        if (response.status === 200) {
          setVerified(true);
        } else {
          console.error("Verification failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    verifyAcceptance();
  }, [executeRecaptcha, leadId, zohoOrgId, token]);

  if (!verified) {
    return <div>Verifying...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 to-indigo-100">
      <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
        <div className="text-center">
          <div className="mb-6">
            <svg
              className="w-16 h-16 text-green-500 mx-auto"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h1 className="text-3xl font-bold text-gray-800 mb-4">
            Thank you, {company}!
          </h1>
          <p className="text-lg text-gray-600 mb-2">
            Your invitation has been accepted.
          </p>
          <p className="text-lg text-gray-600 mb-6">
            We look forward to meeting you!
          </p>
          <div className="border-t border-gray-200 pt-6 mb-6">
            <div className="flex items-center justify-center text-gray-700 mb-2">
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <span>{date}</span>
            </div>
            <div className="flex items-center justify-center text-gray-700">
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{time}</span>
            </div>
          </div>
          <a
            href="https://circle.cloud"
            className="inline-block bg-blue-600 text-white font-semibold px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            Visit Our Website
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
