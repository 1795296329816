// UnauthorizedModal.js
export const UnauthorizedModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "white",
          padding: 20,
          margin: 10,
          borderRadius: 10,
        }}
      >
        <p style={{ margin: 10 }}>
          You are not authorized to view this resource.
        </p>
        <button
          onClick={onClose}
          className="text-md rounded-lg bg-blue-950 px-4 py-2 font-normal text-white shadow-sm hover:bg-blue-900"
        >
          Close
        </button>
      </div>
    </div>
  );
};
