// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import HomePage from "./components/HomePage";
import SearchResultsPage from "./components/SearchResultsPage";
import BookingForm from "./components/BookingForm";
import Login from "./components/Login";
import AdminPage from "./components/AdminPage";
import ThankYouPage from "./components/ThankYouPage";
import LogoutButton from "./components/LogoutButton";
import {
  AntiAuth,
  AuthProvider,
  RequireAuth,
  RequireAdminAuth,
} from "./components/Auth/Provider";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <div>
            <Routes>
              <Route
                path="/login"
                element={
                  <AntiAuth>
                    <Login />
                  </AntiAuth>
                }
              />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <>
                      <LogoutButton />
                      <HomePage />
                    </>
                  </RequireAuth>
                }
              />
              <Route
                path="/admin"
                element={
                  <RequireAdminAuth>
                    <>
                      <LogoutButton />
                      <AdminPage />
                    </>
                  </RequireAdminAuth>
                }
              />
              <Route
                path="/search"
                element={
                  <RequireAuth>
                    <>
                      <LogoutButton />
                      <SearchResultsPage />
                    </>
                  </RequireAuth>
                }
              />
              <Route
                path="/book/:slotId"
                element={
                  <RequireAuth>
                    <>
                      <LogoutButton />
                      <BookingForm />
                    </>
                  </RequireAuth>
                }
              />
              <Route
                path="/thank-you"
                element={
                  <GoogleReCaptchaProvider reCaptchaKey="6LfTLlkqAAAAAHVZLkDoDbm4sPnq3lKPw0YYaVhW">
                    <ThankYouPage />
                  </GoogleReCaptchaProvider>
                }
              />
            </Routes>
          </div>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
