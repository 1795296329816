import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles/calendarStyles.css";
import { Loader } from "./Loader-dark.js";
import { timeStampLog } from "../utils/timeStampLog";

function ManualBookingModal({ isOpen, onClose }) {
  const modalRef = useRef(null);
  const navigate = useNavigate();

  // State variables
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState("08");
  const [minute, setMinute] = useState("00");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [salesPeople, setSalesPeople] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const leadID = new URLSearchParams(window.location.search).get("lead");

  // Date constraints
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  useEffect(() => {
    // Set minDate and maxDate
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    if (currentHour < 18 || (currentHour === 18 && currentMinute === 0)) {
      setMinDate(now);
    } else {
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(8, 0, 0, 0);
      setMinDate(tomorrow);
    }

    const futureDate = new Date(now);
    futureDate.setDate(futureDate.getDate() + 14);
    futureDate.setHours(18, 0, 0, 0);
    setMaxDate(futureDate);

    // Adjust selected date if necessary
    if (date < minDate) {
      setDate(minDate);
    }
  }, [date]);

  // Fetch email and sales people when component mounts or leadID changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (leadID) {
          const emailResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/leadDetails/${leadID}`,
            { credentials: "include" },
          );
          if (!emailResponse.ok) throw new Error("Failed to fetch email");
          const emailData = await emailResponse.json();
          setLeadEmail(emailData.Email);
          setEmail(emailData.Email);
        }

        const salesPeopleResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/salesPeople`,
          { credentials: "include" },
        );
        if (!salesPeopleResponse.ok)
          throw new Error("Failed to fetch sales people");
        const salesPeopleData = await salesPeopleResponse.json();
        setSalesPeople(salesPeopleData);
        if (salesPeopleData.length > 0) {
          setSelectedSalesPerson(salesPeopleData[0]._id);
        }
      } catch (error) {
        timeStampLog(`Error fetching data: ${error.message}`);
        setErrorMessage("Failed to load initial data. Please try again.");
      }
    };

    fetchData();
  }, [leadID]);

  // Input validation
  const validateInputs = () => {
    if (!email.trim()) {
      setErrorMessage("Please enter a valid email address.");
      return false;
    }
    if (!selectedSalesPerson) {
      setErrorMessage("Please select a sales person.");
      return false;
    }
    return true;
  };

  // Handle booking and sending invite
  const handleBookClick = async () => {
    setErrorMessage("");
    if (!validateInputs()) return;

    const timeInHours24Format = parseInt(hour);
    const bookingDateTime = new Date(date);
    bookingDateTime.setHours(timeInHours24Format, parseInt(minute));

    timeStampLog("Booking DateTime:", bookingDateTime.toISOString());

    setIsLoading(true);

    try {
      // First, get the slot
      const slotUrl = `${process.env.REACT_APP_API_URL}/api/dateToSlot/${bookingDateTime.toISOString()}`;
      timeStampLog("Fetching slot URL:", slotUrl);

      const slotResponse = await fetch(slotUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!slotResponse.ok) {
        const slotErrorText = await slotResponse.text();
        timeStampLog(
          "Slot response not OK. Status:",
          slotResponse.status,
          "Response:",
          slotErrorText,
        );
        throw new Error(
          `Failed to get slot information: ${slotResponse.status} ${slotErrorText}`,
        );
      }

      const slotData = await slotResponse.json();
      timeStampLog("Slot data received:", JSON.stringify(slotData, null, 2));

      const requestData = {
        emailRecipient: email,
        leadID: leadID,
        selectedDateTime: bookingDateTime.toISOString(),
        slotID: slotData.slot,
        deviation: slotData.deviationMinutes,
        salesPersonID: selectedSalesPerson,
      };

      timeStampLog("Full request data:", JSON.stringify(requestData, null, 2));

      // Check if requestData is empty or undefined
      if (!requestData || Object.keys(requestData).length === 0) {
        timeStampLog("Error: requestData is empty or undefined");
        throw new Error("Request data is missing or invalid");
      }
      // Log each property of requestData separately
      Object.entries(requestData).forEach(([key, value]) => {
        timeStampLog(`${key}:`, value);
      });

      // Send the invite
      const inviteUrl = `${process.env.REACT_APP_API_URL}/api/calendarInvite`;
      timeStampLog("Sending invite to URL:", inviteUrl);

      const inviteResponse = await fetch(inviteUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(requestData),
      });

      if (!inviteResponse.ok) {
        const errorText = await inviteResponse.text();
        timeStampLog(
          "Invite response not OK. Status:",
          inviteResponse.status,
          "Response:",
          errorText,
        );
        throw new Error(
          `Failed to send invite: ${inviteResponse.status} ${errorText}`,
        );
      }

      const inviteData = await inviteResponse.json();
      timeStampLog(
        "Invite response data:",
        JSON.stringify(inviteData, null, 2),
      );

      timeStampLog("Booking successful");
      navigate(
        `/book/${requestData.slotID}?deviation=${requestData.deviation}&lead=${requestData.leadID}&spid=${requestData.salesPersonID}&inviteEmail=${requestData.emailRecipient}`,
      );
    } catch (error) {
      timeStampLog(`Error in handleBookClick: ${error.message}`);
      setErrorMessage(`Booking failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Disable weekends and dates outside minDate and maxDate
  const isDateDisabled = ({ date, view }) => {
    if (view === "month") {
      const day = date.getDay();
      return (
        day === 0 ||
        day === 6 ||
        date < new Date(minDate.setHours(0, 0, 0, 0)) ||
        date > new Date(maxDate.setHours(23, 59, 59, 999))
      );
    }
    return false;
  };

  // Format date and time for display
  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", { day: "numeric", month: "long" });
  };

  const formatTime = (hour, minute) => {
    const hourNum = parseInt(hour);
    const ampm = hourNum >= 12 ? "PM" : "AM";
    const hour12 = hourNum > 12 ? hourNum - 12 : hourNum;
    return `${hour12}:${minute} ${ampm}`;
  };

  // Time options
  const hours = Array.from({ length: 11 }, (_, i) =>
    (i + 8).toString().padStart(2, "0"),
  );
  const minutes = ["00", "15", "30", "45"];

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="modal-content" ref={modalRef}>
          <div className="modal-header">
            <h2 className="modal-title">Manual Booking</h2>
          </div>
          <div className="modal-body">
            <div className="calendar-container">
              <Calendar
                onChange={setDate}
                value={date}
                minDate={minDate}
                maxDate={maxDate}
                tileDisabled={isDateDisabled}
                className="custom-calendar"
              />
            </div>
            <div className="input-grid">
              <label htmlFor="time-select">Time:</label>
              <div className="input-content time-selection time-row">
                <select
                  id="time-select-hour"
                  value={hour}
                  onChange={(e) => setHour(e.target.value)}
                  className="bordered-input"
                >
                  {hours.map((h) => (
                    <option key={h} value={h}>
                      {h}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  id="time-select-minute"
                  value={minute}
                  onChange={(e) => setMinute(e.target.value)}
                  className="bordered-input"
                >
                  {minutes.map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
              </div>

              <label htmlFor="email-input">Invite email:</label>
              <div className="email-row">
                <input
                  id="email-input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bordered-input"
                />
              </div>

              <label htmlFor="salesperson-select">Booking for:</label>
              <select
                id="salesperson-select"
                value={selectedSalesPerson}
                onChange={(e) => setSelectedSalesPerson(e.target.value)}
                className="bordered-input"
              >
                {salesPeople.map((sp) => (
                  <option key={sp._id} value={sp._id}>
                    {sp.fullName}
                  </option>
                ))}
              </select>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <button className="action-button" onClick={handleBookClick}>
              {`Book for ${formatDate(date)} at ${formatTime(hour, minute)}`}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManualBookingModal;
