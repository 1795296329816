// client/src/components/Login.js

import React from "react";
import ZohoLoginButton from "./ZohoLoginButton";
import "./styles/login.css"; // Ensure this CSS file includes the necessary styles

const Login = () => {
  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <img
            src="https://circle.cloud/wp-content/uploads/2021/12/ccc-icon-blue-2.svg"
            alt="Scheduler Logo"
            className="login-logo"
          />
          <h1 className="login-title">Welcome to Scheduler</h1>
          <p className="login-subtitle">by circle.cloud</p>
        </div>
        <div className="login-content">
          <p>
            Log in to access your scheduling tools and manage your appointments
            efficiently.
          </p>
        </div>
        <div className="login-footer">
          <ZohoLoginButton />
        </div>
      </div>
    </div>
  );
};

export default Login;
