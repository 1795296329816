// client/src/helpers/Cookie.js

const Cookies = {
  getCookie: (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
  deleteCookie: (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  },
  setSearchRedirectCookie: (url) => {
    const encodedUrl = encodeURIComponent(url);
    document.cookie = `searchRedirectUrl=${encodedUrl}; path=/; max-age=3600; SameSite=Lax`;
  },
  getSearchRedirectCookie: () => {
    const name = "searchRedirectUrl=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  },
  deleteAuthCookie: () => {
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  },
};

export default Cookies;
