// client/src/components/ZohoLoginButton.js

import React from "react";
import { useAuth } from "./Auth/Provider";
import "./styles/login.css";

const ZohoLoginButton = () => {
  const { login } = useAuth();

  const handleZohoLogin = () => {
    login("zoho");
  };

  return (
    <button onClick={handleZohoLogin} className="zoho-login-button">
      Log in with Zoho
    </button>
  );
};

export default ZohoLoginButton;
