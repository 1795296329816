import React from "react";

// Import the logging utility
import { timeStampLog } from "../utils/timeStampLog.js";

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const errorHandler = (error) => {
      setHasError(true);
      logError(error);
    };

    window.addEventListener("error", errorHandler);
    return () => window.removeEventListener("error", errorHandler);
  }, []);

  if (hasError) {
    return <ErrorFallback />;
  }

  return children;
}

function ErrorFallback() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-red-600 mb-4">
          Oops! Something went wrong.
        </h1>
        <p className="text-gray-600 mb-4">
          We're sorry for the inconvenience. Our team has been notified and is
          working on a fix.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
}

async function logError(error) {
  const errorDetails = {
    message: error.message,
    stack: error.stack,
    url: window.location.href,
  };

  try {
    const response = await fetch("/api/log-error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(errorDetails),
    });

    if (!response.ok) {
      throw new Error("Failed to log error");
    }

    timeStampLog("Error logged successfully");
  } catch (logError) {
    timeStampLog(`Failed to log error: ${logError.message}`);
  }
}

export default ErrorBoundary;
