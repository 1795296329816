// client/src/axiosConfig.js

import axios from "axios";
import Cookies from "./helpers/Cookie";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Call the logout endpoint to clear the cookie server-side
      try {
        await instance.get("/auth/logout");
      } catch (logoutError) {
        console.error("Error logging out:", logoutError);
      }

      // Only clear the auth cookie client-side
      Cookies.deleteAuthCookie();

      // Save current URL as redirect URL before redirecting to login
      const currentPath = window.location.pathname + window.location.search;
      Cookies.setRedirectCookie(currentPath);

      // Redirect to login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default instance;
