import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./tailwind.output.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the logging utility
import { timeStampLog } from "./utils/timeStampLog.js";

// Add the error handling code here
window.onerror = function (message, source, lineno, colno, error) {
  // Sanitize and validate the data before sending
  const sanitizedMessage = sanitizeErrorMessage(message);
  const sanitizedStack = error ? sanitizeErrorStack(error.stack) : "";
  const sanitizedUrl = sanitizeUrl(window.location.href);

  // Log the error locally
  timeStampLog(`Error: ${sanitizedMessage} at ${sanitizedUrl}`);

  // Send the error to the server with retry logic
  sendErrorToServer({
    message: sanitizedMessage,
    stack: sanitizedStack,
    url: sanitizedUrl,
  });
};

// Helper function to sanitize error messages
function sanitizeErrorMessage(message) {
  // Implement sanitization logic here
  return message.slice(0, 500); // Limit message length
}

// Helper function to sanitize error stack
function sanitizeErrorStack(stack) {
  // Implement sanitization logic here
  return stack.slice(0, 1000); // Limit stack length
}

// Helper function to sanitize URLs
function sanitizeUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.origin}${parsedUrl.pathname}`;
  } catch (error) {
    return "Invalid URL";
  }
}

// Function to send error to server with retry logic
async function sendErrorToServer(errorData) {
  const maxRetries = 3;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await fetch("/api/log-error", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(errorData),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      timeStampLog("Error logged successfully");
      return;
    } catch (error) {
      retries++;
      const delay = Math.pow(2, retries) * 1000; // Exponential backoff
      timeStampLog(`Retry ${retries}/${maxRetries} after ${delay}ms`);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  const errorMessage = `Failed to log error after ${maxRetries} retries`;
  timeStampLog(errorMessage);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
