// AdminPage.js
import React from "react";

function AdminPage() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <h1 className="text-4xl font-semibold text-gray-800">Admin Panel</h1>
    </div>
  );
}

export default AdminPage;
