// client/src/components/Auth/Provider.js

import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UnauthorizedModal } from "../UnauthorizedModal.js";
import axiosInstance from "../../axiosConfig";
import Cookies from "../../helpers/Cookie";

export const AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const location = useLocation();

  const fetchUserDetails = async () => {
    try {
      const response = await axiosInstance.get("/auth/check-auth");
      if (response.data.authenticated) {
        setUser({
          ...response.data.user,
          zohoAdminLevel: response.data.user.zohoAdminLevel,
        });
      } else {
        setUser(null);
        // If not authenticated and on search page, set the searchRedirect cookie
        if (location.pathname.startsWith("/search")) {
          Cookies.setSearchRedirectCookie(location.pathname + location.search);
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setUser(null);
      // If error occurs and on search page, set the searchRedirect cookie
      if (location.pathname.startsWith("/search")) {
        Cookies.setSearchRedirectCookie(location.pathname + location.search);
      }
    } finally {
      setLoading(false);
    }
  };

  const login = (provider) => {
    const redirectUrl = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
    console.log("Redirecting to auth URL:", redirectUrl);
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    fetchUserDetails();
  }, [location]);

  const contextValue = {
    user,
    loading,
    login,
    setUnauthorized: () => setIsUnauthorized(true),
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      <UnauthorizedModal
        isOpen={isUnauthorized}
        onClose={() => setIsUnauthorized(false)}
      />
    </AuthContext.Provider>
  );
}

export function RequireAuth({ children }) {
  const { user, loading, login } = React.useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      const currentPath = location.pathname + location.search;
      if (location.pathname.startsWith("/search")) {
        Cookies.setSearchRedirectCookie(currentPath);
      }
    }
  }, [user, loading, location]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function RequireAdminAuth({ children }) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth.user || auth.user.zohoAdminLevel !== "administrator") {
    // Redirect if not logged in or if the user is not a Zoho administrator
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function AntiAuth({ children }) {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

// Add this custom hook
export function useAuth() {
  return useContext(AuthContext);
}
