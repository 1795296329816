import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const [postcode, setPostcode] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (postcode.trim()) {
      navigate(`/search?postcode=${encodeURIComponent(postcode.trim())}`);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-[#0b1320]">Scheduler</h1>
          <p className="mt-2 text-sm text-[#0b1320]">by circle.cloud</p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <input
              type="text"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-[#0b1320] placeholder-[#0b1320]/50 text-[#0b1320] rounded-t-md focus:outline-none focus:ring-[#E93E0E] focus:border-[#E93E0E] focus:z-10 sm:text-sm"
              placeholder="Enter postcode"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E93E0E] transition-colors duration-200"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </span>
              Get Slots
            </button>
          </div>
        </form>
        <p className="mt-2 text-center text-sm text-[#0b1320]">
          Find the most efficient appointment slots based on drive time
        </p>
      </div>
    </div>
  );
}

export default HomePage;

/*

removed section:
<a
  href="https://crm.zoho.com/crm/org57963813/tab/Leads"
  target="_self"
  className="inline-block rounded bg-blue-500 px-6 py-3 text-lg text-white shadow transition-colors hover:bg-blue-600"
>
  Go to leads
</a>
<button onClick={close}>Close tab</button>
<Link to="/search">Search</Link>
<Link to="/admin" className="mt-4 inline-block">
  Admin Panel
</Link>

*/
